import { useState } from "react";
import axios from "axios";
import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";
import { ProductConversationsIcon } from "@twilio-paste/icons/esm/ProductConversationsIcon";

import { getToken } from "../../api";
// import { InputType } from "../../types";
// import ModalInputField from "../modals/ModalInputField";
import styles from "../../styles";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate } from "@azure/msal-react";

type SetTokenType = (token: string) => void;

interface LoginProps {
  setToken: SetTokenType;
}

async function login(
  initializeSignIn: any,
  setToken: (token: string) => void
): Promise<string> {
  try {
    debugger;
    // const parsed = queryString.parse(location.search);
    // const msToken = (parsed as any).token;
    initializeSignIn(setToken);

    return "";
  } catch (error) {
    debugger;
    return error;
  }
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const openingText = `Hey, I'm new here and I need to learn, would you help me?`;
  const loginPrompt = `If you are willing to help please login with SSO`;
  const openingTextLoggedIn = `Please notice you are going to talk with our chat bot, you shouldn't send any personal information`;
  const loginPromptLoggedIn = `If you agree to terms please login`;
  const { instance, accounts } = useMsal();

  const initializeSignIn = async (setToken: (token: string) => void) => {
    // const r = await instance.loginPopup();
    const loginRequest = {
      scopes: ["User.Read"], // optional Array<string>
    };
    debugger;
    if (accounts.length) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      debugger;
      const response = await instance.acquireTokenSilent(request);
      const msToken = response.accessToken;
      const token = await getToken(msToken);
      if (token === "") {
        return "Something went wrong.";
      }
      localStorage.setItem("msToken", msToken);
      setToken(token);
    }
  };
  return (
    <Box style={styles.loginContainer}>
      <Box style={styles.loginContent}>
        <Box>
          <ProductConversationsIcon
            decorative={true}
            size="sizeIcon90"
            style={styles.logo}
            color="colorTextInverse"
          />
        </Box>
        <div style={styles.loginTitle}>Ella labs </div>
        <div style={styles.subTitle}>Conversations experience</div>
        <Box style={styles.loginForm}>
          <AuthenticatedTemplate>
            <Box style={styles.userInput}>
              <p>{openingTextLoggedIn}</p>
              <p>{loginPromptLoggedIn}</p>
            </Box>
            <Box style={styles.loginButton}>
              <Button
                fullWidth
                variant="primary"
                onClick={async () => {
                  await instance.loginPopup();
                  // initializeSignIn();
                  const error = await login(initializeSignIn, props.setToken);
                  if (error) {
                    setFormError(error);
                  }
                }}
              >
                Continue
              </Button>
            </Box>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Box style={styles.userInput}>
              <p>{openingText}</p>
              <p>{loginPrompt}</p>
            </Box>
            <Box style={styles.loginButton}>
              <Button
                fullWidth
                variant="primary"
                onClick={async () => {
                  await instance.loginPopup();
                  // initializeSignIn();
                  const error = await login(initializeSignIn, props.setToken);
                  if (error) {
                    setFormError(error);
                  }
                }}
              >
                Sign in
              </Button>
            </Box>
          </UnauthenticatedTemplate>
        </Box>
        <Box style={{ paddingTop: 40 }}></Box>
      </Box>
      <Box style={styles.loginBackground}>
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#06033a",
            transform: "skewY(-12deg)",
            transformOrigin: "top right",
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
