import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { Theme } from "@twilio-paste/core/theme";
import { Box } from "@twilio-paste/core";

import App from "./components/App";
import styles from "../src/styles";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "da2e689c-a578-48ac-8bc7-028b94e834a8",
    authority:
      "https://login.microsoftonline.com/d8ff821c-00d8-4793-89a6-d99c3102e372",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
};

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={pca}>
    <Box style={styles.app}>
      <React.StrictMode>
        <Provider store={store}>
          <Theme.Provider theme="default">
            <Box style={styles.app}>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
                rel="stylesheet"
              ></link>
              <App />
            </Box>
          </Theme.Provider>
        </Provider>
      </React.StrictMode>
    </Box>
  </MsalProvider>,
  document.getElementById("root")
);
